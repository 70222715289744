import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import { Header, Popup } from 'semantic-ui-react';
import styled from 'styled-components';

import QuestionIconAccentUrl from 'components/ui/svg/question-circle-regular-accent.svg';
import QuestionIconUrl from 'components/ui/svg/question-circle-regular.svg';

import commonPropTypes from 'utils/commonPropTypes';
import { capitalize } from 'utils/helpers';

import * as svars from 'assets/style/variables';

export const StyledPopup = styled(Popup)`
  z-index: 3;
  &&& {
    padding: ${({ compact }) =>
      compact
        ? `${svars.spaceNormal} ${svars.spaceMedium}`
        : svars.spaceMedium};

    border-radius: ${svars.borderRadius};
    ${({ inverted }) =>
      inverted
        ? `
        background-color: ${svars.colorSecondary};
        color: ${svars.colorWhite};
        `
        : ''}
    &&:before {
      ${({ inverted }) =>
        inverted ? `background-color: ${svars.colorSecondary};` : ''}
      z-index: -1;
    }
  }
`;

function HelpTooltip({
  mouseEnterDelay,
  mouseLeaveDelay,
  trigger,
  headerText,
  triggerText,
  style,
  iconSize,
  position,
  baseColor,
  help,
  disabled,
  hoverable,
  compact,
  inverted,
  marged,
  on,
  ...otherProps
}) {
  const formattedTrigger = trigger || (
    <span
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        verticalAlign: 'middle',
        cursor: ['click', 'focus'].includes(on) ? 'pointer' : 'inherit',
        ...style,
      }}
    >
      <span style={{ display: 'flex' }}>
        {triggerText}
        <img
          style={{
            margin: marged ? `auto ${svars.spaceNormal}` : 'auto',
            height: iconSize,
            width: iconSize,
          }}
          src={baseColor ? QuestionIconUrl : QuestionIconAccentUrl}
          alt=""
        />
      </span>
    </span>
  );
  if (disabled) return formattedTrigger;
  return (
    <StyledPopup
      inverted={inverted}
      hoverable={hoverable}
      on={on}
      mouseEnterDelay={mouseEnterDelay}
      mouseLeaveDelay={mouseLeaveDelay}
      trigger={formattedTrigger}
      position={position}
      compact={compact ? 'true' : undefined}
      {...otherProps}
    >
      {headerText && (
        <Header style={{ zIndex: 1, marginBottom: svars.spaceNormal }} as="h5">
          <Trans id={headerText} />
        </Header>
      )}
      {Array.isArray(help)
        ? help.map((helpElement, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <p key={i}>{helpElement}</p>
          ))
        : help}
    </StyledPopup>
  );
}

HelpTooltip.propTypes = {
  help: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.node])
    .isRequired,
  iconSize: PropTypes.string,
  style: commonPropTypes.style,
  position: PropTypes.string,
  trigger: PropTypes.element,
  triggerText: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.node,
  ]),
  headerText: PropTypes.string,
  mouseEnterDelay: PropTypes.number,
  mouseLeaveDelay: PropTypes.number,
  disabled: PropTypes.bool,
  baseColor: PropTypes.bool,
  compact: PropTypes.bool,
  hoverable: PropTypes.bool,
  inverted: PropTypes.bool,
  on: PropTypes.string,
  marged: PropTypes.bool,
};

HelpTooltip.defaultProps = {
  mouseEnterDelay: 200,
  mouseLeaveDelay: 150,
  trigger: null,
  triggerText: null,
  position: 'top left',
  iconSize: svars.fontSizeSmaller,
  style: {},
  disabled: false,
  baseColor: false,
  headerText: undefined,
  compact: false,
  hoverable: true,
  inverted: true,
  on: 'hover',
  marged: true,
};

const formatItems = (items, getItemText, nMaxItems) => {
  const displayTruncatedList = items.length > nMaxItems + 1;
  const formatted = (
    displayTruncatedList ? items.slice(0, nMaxItems) : items
  ).map((item) => getItemText(item));
  if (displayTruncatedList) {
    const nAdditionalElement = items.length - nMaxItems;
    formatted.push(
      <i style={{ fontSize: svars.fontSizeSmaller }}>
        (+{t`${nAdditionalElement} éléments supplémentaires`})
      </i>
    );
  }
  return formatted;
};
function ListHelpTooltip({
  title,
  items,
  nMaxItems,
  getItemText,
  ...otherProps
}) {
  return (
    <HelpTooltip
      {...otherProps}
      help={
        <div>
          {typeof title === 'string' ? capitalize(title) : title}
          {!(items && items.length) ? (
            items
          ) : (
            <ul style={{ paddingInlineStart: svars.spaceMediumLarge }}>
              {formatItems(items, getItemText, nMaxItems).map((item) => (
                <li
                  style={{ margin: `${svars.spaceNormal} 0` }}
                  key={`tooltip-${item}`}
                >
                  {item}
                </li>
              ))}
            </ul>
          )}
        </div>
      }
    />
  );
}

ListHelpTooltip.propTypes = {
  ...omit(HelpTooltip.propTypes, ['help']),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  ),
  getItemText: PropTypes.func,
  nMaxItems: PropTypes.number,
};

ListHelpTooltip.defaultProps = {
  ...HelpTooltip.defaultProps,
  title: undefined,
  nMaxItems: 4,
  items: [],
  getItemText: (item) => (item ? item.name : item),
};

export { ListHelpTooltip };
export default HelpTooltip;
