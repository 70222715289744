import React from 'react';

import PropTypes from 'prop-types';

import { getSvgSentimentGradient } from 'components/customer/visualization/SvgSentimentGradient';

import * as styleVariables from 'assets/style/variables';

function SentimentScale({
  minSentiment,
  maxSentiment,
  width,
  cursorSentiment,
  id,
}) {
  const gradientName = `sc-${id}`;
  const scaleOrigin = 0;
  const selectedWidth = width || styleVariables.treemapColorScaleWidth;
  const cursorX =
    cursorSentiment != null &&
    1 +
      scaleOrigin +
      ((cursorSentiment - minSentiment) / (maxSentiment - minSentiment)) *
        (selectedWidth - 6);
  return (
    <svg height={styleVariables.treemapColorScaleHeight} width={selectedWidth}>
      {getSvgSentimentGradient(
        gradientName,
        // This function's parameter is usually the list of sentiments, however we
        // need to use min and max sentiment to keep a unique scale for a hierarchical treemap
        [minSentiment, maxSentiment],
        true,
        true
      )}
      <g>
        <rect
          x={scaleOrigin}
          y="0"
          width={selectedWidth - 4}
          height={styleVariables.treemapColorScaleHeight}
          fill={`url(#${gradientName})`}
          rx="8"
          ry="8"
        />
        {cursorSentiment != null && (
          <rect
            x={cursorX}
            y="0"
            width="3"
            height={styleVariables.treemapColorScaleHeight}
            fill="black"
            rx="20"
            ry="20"
          />
        )}
      </g>
    </svg>
  );
}

SentimentScale.propTypes = {
  id: PropTypes.string,
  minSentiment: PropTypes.number,
  maxSentiment: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  cursorSentiment: PropTypes.number,
};

SentimentScale.defaultProps = {
  id: 'scale',
  minSentiment: -1,
  maxSentiment: 1,
  width: undefined,
  cursorSentiment: undefined,
};

export default React.memo(SentimentScale);
