import { useCallback, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import copyToClipboard from 'copy-to-clipboard';

import { StyledPopup } from 'components/ui/HelpTooltip';

import * as svars from 'assets/style/variables';

import { HoverableIconButton } from './icon/HoverableIcon';

const IconStyled = styled(Icon)`
  &&& {
    color: ${(props) =>
      props.clicked ? svars.colorSuccessHover : svars.colorPrimary};
    font-size: ${svars.fontSizeXLarge};
  }
`;

const StyledText = styled.span``;

const timeoutLength = 1700;

const CopyableContainer = styled.span`
  display: inline-flex;
  width: ${({ fluid }) => (fluid ? '100%' : 'auto')};
  color: inherit;

  &:hover {
    cursor: pointer;
    color: ${svars.accentColorHover};

    & ${IconStyled} {
      color: ${svars.accentColor};
      animation-duration: 500ms;
      animation-name: pulse;
    }
  }
`;

function CopyToClipboard({
  trigger,
  text,
  confirmedCopyMessage,
  iconName,
  children,
  style,
  fluid,
}) {
  const [isCopied, setIsCopied] = useState(false);
  const clickTimeout = useRef();
  const clearClickTimeout = () => {
    if (clickTimeout) {
      clearTimeout(clickTimeout.current);
      clickTimeout.current = null;
    }
  };
  const copyText = useCallback(
    (e) => {
      e.stopPropagation();
      clearClickTimeout();
      copyToClipboard(typeof text === 'function' ? text() : text);
      setIsCopied(true);
      // eslint-disable-next-line react/no-unused-class-component-methods
      clickTimeout.current = setTimeout(() => {
        setIsCopied(false);
      }, timeoutLength);
    },
    [text]
  );

  return (
    <StyledPopup
      compact
      inverted
      size="tiny"
      trigger={
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <span onClick={copyText} style={style}>
          {trigger || (
            <CopyableContainer
              data-testid="copy-to-clipboard"
              fluid={fluid ? 'true' : null}
            >
              {children ? (
                <span
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    margin: 'auto 0',
                    marginRight: svars.spaceNormal,
                    flexGrow: 1,
                  }}
                >
                  {children}
                </span>
              ) : null}
              <HoverableIconButton
                name={iconName}
                data-testid="copy-to-clipboard"
                fluid={fluid ? 'true' : null}
                style={{ marginLeft: children ? svars.spaceNormal : 'inherit' }}
                onClick={copyText}
              />
            </CopyableContainer>
          )}
        </span>
      }
      open={isCopied}
      content={<StyledText>{confirmedCopyMessage}</StyledText>}
      on="click"
      position="top center"
    />
  );
}

CopyToClipboard.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  confirmedCopyMessage: PropTypes.string,
  iconName: PropTypes.string,
  children: PropTypes.node,
  trigger: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.objectOf(PropTypes.any),
  fluid: PropTypes.bool,
};

CopyToClipboard.defaultProps = {
  confirmedCopyMessage: 'Copié',
  iconName: 'copy',
  children: null,
  trigger: null,
  style: {},
  fluid: false,
};

export default CopyToClipboard;
