import { useState } from 'react';

import { Form, Icon } from 'semantic-ui-react';

import HoverableIcon from 'components/ui/icon/HoverableIcon';
import { TextInput } from 'components/ui/inputs/TextInput';

function PasswordFormField({ ...inputProps }) {
  const [hiddenPassword, toggleHidePassword] = useState(true);

  return (
    <Form.Field>
      <TextInput
        icon
        iconPosition="left"
        type={hiddenPassword ? 'password' : 'text'}
        autoComplete="current-password"
        {...inputProps}
      >
        <Icon name="lock" />
        <input />
        <HoverableIcon
          onClick={() => toggleHidePassword(!hiddenPassword)}
          name={hiddenPassword ? 'eye slash outline' : 'eye'}
          style={{ margin: 0, left: 'auto', right: '-15%' }}
        />
      </TextInput>
    </Form.Field>
  );
}

export default PasswordFormField;
