import getScatterLabelling from 'automatic-scatter-labelling';

import { splitTextLabel } from 'utils/helpers';

import { getLabelPosition } from './ScatterChartLabel';

const textSpanHeight = 9;

const LABEL_MAX_WIDTH = 120;

/**
 * Generate scatter chart label format and positions asynchronously.
 *
 * @param {Object} label the scatter component props
 * @param {Object} point a method to get label text from scatter item
 * @param {Object} chartBbox
 */
const getLabelCoordinates = (label, point, chartBbox) => {
  if (label) return label.rectangle;
  const rectangle = {
    top: point.position.y - point.label.height,
    bottom: point.position.y,
    left: point.position.x - point.label.width / 2,
    right: point.position.x + point.label.width / 2,
  };
  // If label rectangle goes over chart top, lower it
  if (rectangle.top < chartBbox.top) {
    rectangle.top = chartBbox.top;
    rectangle.bottom = chartBbox.top + point.label.height;
  }
  // If label rectangle goes beyond chart right, move it left
  if (rectangle.left < chartBbox.left) {
    rectangle.left = chartBbox.left;
    rectangle.right = chartBbox.left + point.label.width;
    // If label rectangle goes beyond chart left, move it right
  } else if (rectangle.right > chartBbox.right) {
    rectangle.right = chartBbox.right;
    rectangle.left = chartBbox.right - point.label.width;
  }
  return rectangle;
};

/**
 * Generate scatter chart label format and positions asynchronously
 *
 * @param {Array.Object} scatterProps the scatter component props
 * @param {function} getText a method to get label text from scatter item
 * @callback callback store labels generated asynchronously
 */
const computeScatterLabels = (scatterProps, getText, callback) => {
  const points = scatterProps.points.map(({ cx, cy, ...item }) => {
    const text = getText(item);
    const splitted = splitTextLabel(
      text,
      Math.round(LABEL_MAX_WIDTH / 10),
      true
    );

    const width = Math.max(...splitted.map((split) => split.length)) * 10 || 2;
    const height = textSpanHeight * splitted.length || 2;
    return {
      ...item,
      id: text,
      position: {
        x: cx,
        y: cy,
      },
      label: {
        height,
        width,
      },
    };
  });
  const chartBbox = {
    top: scatterProps.top,
    bottom: -scatterProps.top + scatterProps.height,
    left: scatterProps.left,
    right: scatterProps.left + scatterProps.width,
    width: scatterProps.width,
    height: scatterProps.height,
  };
  getScatterLabelling(points, {
    NUMBER_OF_RAYS: 12,
    MAX_NUMBER_OF_ITERATIONS: 10,
    radius: 8,
    bbox: chartBbox,
  }).then((labels) => {
    const displayedLabels = points.map((point) => {
      const label = labels.find(({ id }) => id === point.id);
      const rectangle = getLabelCoordinates(label, point, chartBbox);
      return {
        ...point,
        displayed: true,
        checked: !!label,
        position: getLabelPosition(rectangle),
        label: point.id,
        value: point.id,
        key: point.id,
        id: point.id,
      };
    });
    callback(displayedLabels);
  });
};

export default computeScatterLabels;
