import { getInterpolatedColor } from 'utils/colors';

import * as svars from 'assets/style/variables';

const getNeutralColorGradientOffset = (minValue, maxValue) => {
  if (maxValue <= 0) {
    return 0;
  }
  if (minValue >= 0) {
    return 1;
  }
  return maxValue / (maxValue - minValue);
};

/**
 *  Create an SVG linear gradient for sentiment representation.
 *
 * @param {String} gradientName the name of the gradient.
 * @param {Array<Number>} sentiments the involved sentiment values.
 */
export const getSvgSentimentGradient = (
  gradientName,
  sentiments,
  isHorizontal = false,
  box = false
) => {
  const maxSentiment = Math.max(...sentiments);
  const minSentiment = Math.min(...sentiments);
  const neutralColorGradientOffset = getNeutralColorGradientOffset(
    minSentiment,
    maxSentiment
  );
  return (
    <defs>
      <linearGradient
        id={gradientName}
        // For horizontal x1 is set to 0 and not x2 (as direction are "opposed")
        x1={isHorizontal ? '1' : '0'}
        y1="0"
        x2="0"
        y2={isHorizontal ? '0' : '1'}
        // If this is not set, for some reason the gradient is not applied when all sentiment values are 0
        gradientUnits={box ? 'objectBoundingBox' : 'userSpaceOnUse'}
      >
        <stop
          offset="0"
          stopColor={getInterpolatedColor(maxSentiment)}
          stopOpacity={1}
        />
        {/* if neutralColorGradientOffset is 1, sentiment is never crossing 0 and only 2 colors are necessary to define a gradient  */}
        {neutralColorGradientOffset < 1 && (
          <stop
            offset={`${neutralColorGradientOffset}`}
            stopColor={svars.absoluteMidColor}
            stopOpacity={1}
          />
        )}
        <stop
          offset="1"
          stopColor={getInterpolatedColor(minSentiment)}
          stopOpacity={1}
        />
      </linearGradient>
    </defs>
  );
};
export const getHorizontalVolumeSvgGradient = () => (
  <defs>
    <linearGradient id="colorHorizontalBarVolume" x1="0" y1="0" x2="1" y2="0">
      <stop
        offset="0"
        stopColor={svars.minVolumeGradientColor}
        stopOpacity={0.25}
      />
      <stop
        offset="0.3"
        stopColor={svars.minVolumeGradientColor}
        stopOpacity={0.5}
      />
      <stop offset="0.8" stopColor={svars.volumeColor} stopOpacity={0.5} />
      <stop
        offset="1.0"
        stopColor={svars.volumeStrokeColor}
        stopOpacity={0.5}
      />
    </linearGradient>
  </defs>
);

export const getSvgVolumeGradient = () => (
  <defs>
    <linearGradient id="colorAreaVolume" x1="0" y1="0" x2="0" y2="1">
      <stop offset="0" stopColor={svars.volumeColor} stopOpacity={0.5} />
      <stop
        offset="0.5"
        stopColor={svars.minVolumeGradientColor}
        stopOpacity={0.1}
      />
      <stop offset="1.0" stopColor="#FFFFFF" stopOpacity={0.5} />
    </linearGradient>
    <linearGradient id="colorBarVolume" x1="0" y1="1" x2="0" y2="0">
      <stop
        offset="0"
        stopColor={svars.minVolumeGradientColor}
        stopOpacity={0.3}
      />
      <stop
        offset="0.3"
        stopColor={svars.minVolumeGradientColor}
        stopOpacity={0.7}
      />
      <stop offset="0.8" stopColor={svars.volumeColor} stopOpacity={0.7} />
      <stop
        offset="1.0"
        stopColor={svars.volumeStrokeColor}
        stopOpacity={0.7}
      />
    </linearGradient>
    <linearGradient id="colorStrokeVolume" x1="0" y1="0" x2="0" y2="1">
      <stop offset="0" stopColor={svars.volumeStrokeColor} stopOpacity={0.8} />
      <stop offset="0.5" stopColor={svars.volumeColor} stopOpacity={0.8} />
    </linearGradient>
  </defs>
);

export const getEmptySvgPattern = () => (
  <defs>
    <pattern
      id={svars.emptyCategoryGradientId}
      width="8"
      height="10"
      patternUnits="userSpaceOnUse"
      patternTransform="rotate(45 50 50)"
    >
      <line stroke={svars.colorLightGrey} strokeWidth="7px" y2="10" />
    </pattern>
  </defs>
);
