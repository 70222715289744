import PropTypes from 'prop-types';

import * as svars from 'assets/style/variables';

function MandatoryField({ mandatory }) {
  if (!mandatory) {
    return null;
  }
  return (
    <span
      style={{
        color: svars.colorDanger,
        fontWeight: svars.fontWeightSemiBold,
        marginLeft: svars.spaceNormal,
      }}
    >
      *
    </span>
  );
}

MandatoryField.propTypes = {
  mandatory: PropTypes.bool.isRequired,
};
MandatoryField.defaultProps = {};

export default MandatoryField;
