import { useCallback, useMemo, useState } from 'react';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';

import { i18n } from '@lingui/core';
import { t, msg } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

import ScatterChartWithExportModal from 'components/customer/visualization/scatter-chart/ScatterChartWithExportModal';
import StyledHeader from 'components/ui/Header';
import ButtonGroup from 'components/ui/button/ButtonGroup';
import { SecondaryTabButton } from 'components/ui/button/TabButton';
import { exportElementAsImage } from 'components/ui/button/export-as/ExportAsImage';
import { exportAsXls } from 'components/ui/button/export-as/ExportAsXls';
import { ChartMenu } from 'components/ui/dashboard/DashboardElement';
import InTextDropdown from 'components/ui/inputs/InTextDropdown';
import EmptyDataVisualization from 'components/ui/visualization/EmptyDataVisualization';
import LoadingDataVisualization from 'components/ui/visualization/LoadingDataVisualization';

import { logEvent } from 'utils/analytics';
import commonPropTypes from 'utils/commonPropTypes';

import * as svars from 'assets/style/variables';

import TopBottomTable from './TopBottomTable';

const VIEWS_ITEMS = [
  {
    key: 'graphical',
    i18nLabel: msg({ id: "graphical-view" }),
    value: false,
  },
  {
    key: 'table',
    i18nLabel: msg({ id: "table-view" }),
    value: true,
  },
];

const DISPLAY_OPTIONS = [
  { key: 1, value: '1M', i18nText: msg({ id: "30-days" }) },
  { key: 2, value: '3M', i18nText: msg({ id: "90-days" }) },
  { key: 3, value: '12M', i18nText: msg({ id: "360-days" }) },
  { key: 4, value: '100Y', i18nText: msg({ id: "the-complete-period" }) },
];

/**
 * Compile a filename for chart exports - get text directly in DOM to avoid i18n management
 *
 * @param {string} displayOptionId
 * @memberof TopBottomPanel
 */
const getExportName = (title, timeFrame) =>
  `${title} ${timeFrame ? i18n._(timeFrame) : ''}`;

const computeTopIndexRadius =
  (maxTopIndex) =>
  ({ top_index }) => {
    const index = 1 + Math.abs(top_index) / maxTopIndex;
    return 5 + (index * index - 1) * 8;
  };

function TopBottomPanel({
  title,
  axis,
  conceptSelection,
  loading,
  displayTable,
  displayOption,
  onToggleDisplayTable,
  onDisplayOptionChange,
  entityLabelFormatter,
  ontologies,
  testid,
}) {
  const [exportModalIsOpen, setExportModalIsOpen] = useState(false);
  const toggleExportModal = useCallback(
    () => setExportModalIsOpen(!exportModalIsOpen),
    [exportModalIsOpen]
  );
  const navigate = useNavigate();
  const { viewFacetId } = useParams();
  const navigateToExploration = useCallback((conceptId) => {
    navigate({
      pathname: `../analyze/${viewFacetId}/explore`,
      search: createSearchParams({
        concept: conceptId,
      }).toString(),
    });
  }, []);
  const onShapeClick = useCallback(
    (event, { db_concept }) => {
      navigateToExploration(db_concept);
    },
    [navigateToExploration]
  );
  const legacyConceptLabelFormatter = useCallback(
    (item, payload) => entityLabelFormatter('concept', item, payload),
    [entityLabelFormatter]
  );
  const filteredDisplayOptions = useMemo(
    () =>
      DISPLAY_OPTIONS.filter((item) => conceptSelection?.[item.value]?.length),
    [conceptSelection]
  );

  const data = conceptSelection?.[displayOption];
  const maxTopIndex = data
    ? Math.max(...data.map(({ top_index }) => Math.abs(top_index)))
    : 1;
  const isDataEmpty =
    conceptSelection &&
    conceptSelection[displayOption] &&
    !conceptSelection[displayOption].length;
  const chartId = `topBottom-${axis}-${displayTable}`;
  const displayOptionId = `display-option-${axis}`;
  const exportName = getExportName(
    title,
    DISPLAY_OPTIONS.find((option) => option.value === displayOption)?.i18nText
  );
  const exportDataAsXls = useCallback(() => {
    const columns = [
      {
        label: t({ id: "concept" }),
        key: 'db_concept',
        accessor: (row) => legacyConceptLabelFormatter(row.db_concept),
      },
      { label: t({ id: "volume" }), key: 'n_chunks' },
      { label: t({ id: "sentiment" }), key: 'average_sentiment' },
      { label: t({ id: "contribution" }), key: 'top_index' },
    ];
    exportAsXls(
      data,
      columns,
      null,
      exportName,
      null,
      () => {},
      null,
      3000,
      20
    );
  }, [data, exportName]);
  const exportAsImage = useCallback(() => {
    if (displayTable) {
      // Export table as image
      exportElementAsImage(document, chartId, exportName, () => {
        logEvent({
          category: 'Customer - Analyze',
          action: 'Export image',
          label: exportName,
        });
      });
    } else {
      toggleExportModal();
    }
  }, [chartId, displayTable, toggleExportModal]);
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={16}>
          <StyledHeader>
            {title}
            <InTextDropdown
              fluid
              options={filteredDisplayOptions}
              onChange={onDisplayOptionChange}
              value={displayOption}
              style={{
                paddingLeft: svars.spaceNormal,
                display: 'inline-flex',
                width: 'auto',
              }}
              id={displayOptionId}
            />
          </StyledHeader>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={14}>
          <ButtonGroup
            as={SecondaryTabButton}
            items={VIEWS_ITEMS}
            onChange={onToggleDisplayTable}
            value={displayTable}
            testid="switch-view-button"
          />
        </Grid.Column>
        <Grid.Column
          width={2}
          verticalAlign="middle"
          floated="right"
          textAlign="right"
          style={{ padding: 0 }}
        >
          <ChartMenu
            exportAsImage={exportAsImage}
            exportDataAsXls={exportDataAsXls}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row centered>
        {(loading && <LoadingDataVisualization height={svars.chartHeight} />) ||
          (isDataEmpty && (
            <Grid.Column>
              <EmptyDataVisualization height={svars.chartHeight} />
            </Grid.Column>
          )) || (
            <Grid.Column style={{ height: svars.chartHeight }}>
              {displayTable ? (
                <TopBottomTable
                  exportId={chartId}
                  axis={axis}
                  conceptSelection={conceptSelection}
                  displayOption={displayOption}
                  ontologies={ontologies}
                  //  conceptLabelFormatter={conceptLabelFormatter}
                  onLegendClick={navigateToExploration}
                  data-testid={testid}
                />
              ) : (
                <ScatterChartWithExportModal
                  isAnimationActive={false}
                  data={data}
                  categoryDataKey="db_concept"
                  labelFormatter={legacyConceptLabelFormatter}
                  useSentimentColor
                  height={svars.chartHeight}
                  radius={20}
                  getRadius={computeTopIndexRadius(maxTopIndex)}
                  radiusTooltipFieldKey="top_index"
                  chartId={chartId}
                  modalIsOpen={exportModalIsOpen}
                  onCloseModal={toggleExportModal}
                  exportName={exportName}
                  onShapeClick={onShapeClick}
                />
              )}
            </Grid.Column>
          )}
      </Grid.Row>
    </Grid>
  );
}

TopBottomPanel.propTypes = {
  title: PropTypes.string.isRequired,
  axis: PropTypes.oneOf(['top', 'bottom']).isRequired,
  conceptSelection: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        ontology_name: PropTypes.string,
        db_concept: PropTypes.string,
        top_index: PropTypes.number,
      })
    )
  ),
  ontologies: commonPropTypes.ontologies.isRequired,
  entityLabelFormatter: PropTypes.func.isRequired,
  displayTable: PropTypes.bool.isRequired,
  displayOption: PropTypes.string.isRequired,
  onToggleDisplayTable: PropTypes.func.isRequired,
  onDisplayOptionChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  testid: PropTypes.string,
};

TopBottomPanel.defaultProps = {
  conceptSelection: undefined,
  loading: false,
  testid: undefined,
};
export default TopBottomPanel;
