import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';

import { getSvgSentimentGradient } from 'components/customer/visualization/SvgSentimentGradient';

import config from 'config';
import capitalizedTranslation from 'utils/i18n';

import * as styleVariables from 'assets/style/variables';

const markpointValues = [
  10, 20, 50, 100, 500, 1000, 2000, 5000, 10000, 20000, 50000, 100000,
];

const getLegendMarkpoints = (minValue, maxValue) => {
  let minDiff = 10000000000000;
  let currentDiff = Math.abs(minValue - markpointValues[0]);
  let ind = 0;
  while (currentDiff < minDiff) {
    minDiff = currentDiff;
    ind += 1;
    currentDiff = Math.abs(minValue - markpointValues[ind]);
  }
  const minInd = Math.max(0, ind - 1);
  let currentMaxDiff = Math.abs(
    maxValue - markpointValues[markpointValues.length - 1]
  );
  let maxDiff = 10000000;
  ind = 1;
  while (currentMaxDiff < maxDiff) {
    maxDiff = currentMaxDiff;
    ind += 1;
    currentMaxDiff = Math.abs(
      maxValue - markpointValues[markpointValues.length - ind]
    );
  }

  const maxInd = Math.min(
    markpointValues.length - 1,
    markpointValues.length - ind + 1
  );
  return [
    ...new Set([
      markpointValues[minInd],
      markpointValues[minInd + Math.round((maxInd - minInd) / 2)],
      markpointValues[maxInd],
    ]),
  ];
};

function CompetitivePositioningChartLegend({
  domain,
  chartHeight,
  chartWidth,
  scale,
}) {
  const legendX = chartWidth - styleVariables.competitivePositioningLegendWidth;
  const legendY =
    chartHeight - styleVariables.competitivePositioningLegendHeight - 1;
  const legendCenterX = styleVariables.competitivePositioningLegendWidth / 2;

  const legendContentY = 1.5 * styleVariables.baseChartMargin;
  const markpoints = getLegendMarkpoints(...domain);
  return (
    <g transform={`translate(${legendX}, ${legendY})`}>
      {getSvgSentimentGradient(
        'treemapSentiment',
        config.SENTIMENT_DOMAIN,
        true,
        true
      )}

      <rect
        x={0}
        y={0}
        width={styleVariables.competitivePositioningLegendWidth}
        height={styleVariables.competitivePositioningLegendHeight}
        rx="8"
        ry="8"
        fill={styleVariables.colorWhite}
        stroke={styleVariables.colorGreyMedium}
      />
      <text
        x={legendCenterX}
        y={legendContentY}
        dominantBaseline="middle"
        textAnchor="middle"
        alignmentBaseline="hanging"
        style={{
          fontWeight: styleVariables.fontWeightMedium,
          fontSize: styleVariables.fontSizeBase,
        }}
      >
        <Trans id="legend" render={capitalizedTranslation} />
      </text>
      <g
        transform={`translate(${
          1 * styleVariables.baseChartMargin
        }, ${legendContentY})`}
        style={{ fontWeight: 300, fontSize: styleVariables.fontSizeXSmall }}
      >
        <text
          x={0}
          y={styleVariables.textSpanHeight * 1.2}
          dominantBaseline="middle"
          textAnchor="start"
          alignmentBaseline="hanging"
          style={{ fontSize: styleVariables.fontSizeSmall }}
        >
          <Trans id="volume" render={capitalizedTranslation} />
        </text>
        {markpoints.map((radius, i) => {
          const scaledRadius = Math.sqrt(scale(radius) / Math.PI);
          return (
            <g
              transform={`translate(${
                (i *
                  (styleVariables.competitivePositioningLegendWidth -
                    2 * styleVariables.baseChartMargin)) /
                markpoints.length
              }, ${legendContentY + styleVariables.textSpanHeight * 2.2})`}
              key={`legend-item-${radius}`}
            >
              <circle
                cx={scaledRadius}
                cy={0}
                fill={styleVariables.colorGreyMedium}
                r={scaledRadius}
              />
              <text
                dominantBaseline="start"
                textAnchor="middle"
                dx={scaledRadius}
                dy={25}
              >
                {radius}
              </text>
            </g>
          );
        })}
        <text
          x={0}
          y={styleVariables.textSpanHeight * 5.2}
          dominantBaseline="middle"
          textAnchor="start"
          alignmentBaseline="hanging"
          style={{ fontSize: styleVariables.fontSizeSmall }}
        >
          <Trans id="sentiment" render={capitalizedTranslation} />
        </text>
        <rect
          x={0}
          y={styleVariables.textSpanHeight * 6.5}
          width={styleVariables.competitivePositioningLegendWidth * 0.8}
          height={styleVariables.treemapColorScaleHeight / 4}
          fill="url(#treemapSentiment)"
          rx="5"
          ry="5"
        />
        <text
          x={0}
          alignmentBaseline="hanging"
          textAnchor="middle"
          y={
            styleVariables.textSpanHeight * 6.5 +
            styleVariables.treemapColorScaleHeight
          }
        >
          {config.SENTIMENT_DOMAIN[0]}
        </text>
        <text
          x={(styleVariables.competitivePositioningLegendWidth * 0.8) / 2}
          alignmentBaseline="hanging"
          y={
            styleVariables.textSpanHeight * 6.5 +
            styleVariables.treemapColorScaleHeight
          }
          textAnchor="middle"
        >
          0
        </text>
        <text
          textAnchor="middle"
          x={styleVariables.competitivePositioningLegendWidth * 0.8}
          alignmentBaseline="hanging"
          y={
            styleVariables.textSpanHeight * 6.5 +
            styleVariables.treemapColorScaleHeight
          }
        >
          {config.SENTIMENT_DOMAIN[1]}
        </text>
      </g>
    </g>
  );
}

CompetitivePositioningChartLegend.propTypes = {
  domain: PropTypes.arrayOf(PropTypes.number).isRequired,
  chartHeight: PropTypes.number.isRequired,
  chartWidth: PropTypes.number.isRequired,
  scale: PropTypes.func.isRequired,
};

export default CompetitivePositioningChartLegend;
