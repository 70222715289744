import { Component } from 'react';

import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';

import {
  Cell,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis,
} from 'recharts';

import SentimentCell from 'components/ui/cells/SentimentCell';
import CustomAxisTick from 'components/ui/visualization/CustomAxisTick';
import EmptyDataVisualization from 'components/ui/visualization/EmptyDataVisualization';
import LoadingDataVisualization from 'components/ui/visualization/LoadingDataVisualization';
import { TooltipContainer } from 'components/ui/visualization/RechartsTooltip';
import { ScatterChart } from 'components/ui/visualization/StyledChart';

import { getInterpolatedColor, linearInterpolation } from 'utils/colors';
import commonPropTypes from 'utils/commonPropTypes';

import * as styleVariables from 'assets/style/variables';

import capitalizedTranslation from 'utils/i18n';
import CompetitivePositioningChartLegend from './CompetitivePositioningChartLegend';

/**
 * Custom min-max scale function, of the form
 * scale : x = alpha . x + beta
 * where alpha and beta are calculated so domain and range are scaled.
 * @returns
 */
function customScale(minSize = 90, maxSize = 700) {
  const myScale = () => {
    let minValue = 0;
    let maxValue = 0;
    let alpha = 0;
    let beta = 0;

    function scale(x) {
      if (x === null) return null;
      return alpha * x + beta;
    }
    scale.range = (range) => {
      if (range) {
        [minValue, maxValue] = range;
        scale.compute();
      }
      return scale;
    };

    scale.domain = (domain) => {
      if (domain) scale.compute();
      return scale;
    };
    scale.map = (x) => x;

    scale.compute = () => {
      alpha = (maxSize - minSize) / (maxValue * (1 - minValue / maxValue));
      beta = minSize - alpha * minValue;
    };
    return scale;
  };

  return myScale();
}

/**
 * Compute the maximum number of characters authorized on one line
 * based on absolute threshold an the list of labels to display.
 *
 * @param {Array} items
 * @param {Function} valueGetter
 * @param {Number} absoluteMinValue
 * @param {Number} absoluteMaxValue
 * @returns the maximum number of characters.
 */
const getMaxCharsValue = (
  items,
  valueGetter,
  absoluteMinValue,
  absoluteMaxValue
) => {
  const maxChars = Math.max(...items.map(valueGetter), 0);
  return Math.min(maxChars, absoluteMaxValue);
};

class CompetitivePositioningChart extends Component {
  static parseDomain = (data) => [
    Math.min(0, ...data.map((entry) => entry.n_chunks)),
    Math.max(0, ...data.map((entry) => entry.n_chunks)),
  ];

  constructor(props) {
    super(props);
    this.state = {
      yPositionMouseOver: -1,
      ...this.setValuesToState(),
    };
    this.customScale = customScale();
  }

  componentDidUpdate(prevProps) {
    const { conceptData, conceptKpis, competitionConceptKpis } = this.props;
    if (
      conceptData &&
      conceptKpis &&
      (prevProps.conceptData !== conceptData ||
        prevProps.competitionConceptKpis !== competitionConceptKpis ||
        prevProps.conceptKpis !== conceptKpis)
    ) {
      this.setState(this.setValuesToState());
    }
  }

  setValuesToState() {
    const {
      conceptData,
      competitionConceptKpis,
      conceptKpis,
      entityLabelFormatter,
    } = this.props;

    const conceptsToYTicks = {};
    const itemsToXTicks = {};
    const yTicksToConcepts = {};
    const xTicksToItems = {};
    const data = [];
    let productHierarchyGroupIndex = 0;

    (conceptData || []).forEach((item, index) => {
      let selectedConceptKpi = null;
      if (conceptKpis) {
        Object.values(conceptKpis).forEach((conceptIdToKpis) => {
          if (conceptIdToKpis?.[item.db_concept]) {
            [selectedConceptKpi] = conceptIdToKpis[item.db_concept];
          }
        });
      }
      if (!conceptsToYTicks[item.db_concept]) {
        conceptsToYTicks[item.db_concept] = {};
      }
      conceptsToYTicks[item.db_concept] = index;

      yTicksToConcepts[index] = [item.db_concept];

      if (
        selectedConceptKpi &&
        itemsToXTicks[selectedConceptKpi?.product_hierarchy_group] === undefined
      ) {
        itemsToXTicks[selectedConceptKpi?.product_hierarchy_group] =
          productHierarchyGroupIndex;
        xTicksToItems[productHierarchyGroupIndex] =
          selectedConceptKpi?.product_hierarchy_group;
        productHierarchyGroupIndex += 1;
      }

      // Push base product hierarchy data point
      data.push({
        ...selectedConceptKpi,
        x: itemsToXTicks[selectedConceptKpi?.product_hierarchy_group],
        y: conceptsToYTicks[item.db_concept],
      });
      (competitionConceptKpis
        ? competitionConceptKpis[item.db_concept]
        : []
      ).forEach((productHierarchyGroupItem) => {
        if (
          itemsToXTicks[productHierarchyGroupItem.product_hierarchy_group] ===
          undefined
        ) {
          itemsToXTicks[productHierarchyGroupItem.product_hierarchy_group] =
            productHierarchyGroupIndex;
          xTicksToItems[productHierarchyGroupIndex] =
            productHierarchyGroupItem.product_hierarchy_group;
          productHierarchyGroupIndex += 1;
        }
        // Push base product hierarchy data point
        data.push({
          ...productHierarchyGroupItem,
          x: itemsToXTicks[productHierarchyGroupItem.product_hierarchy_group],
          y: conceptsToYTicks[item.db_concept],
        });
      });
    });

    const maxCharsXAxis = getMaxCharsValue(
      Object.values(xTicksToItems),
      (item) => {
        const label = entityLabelFormatter('productHierarchyGroup', item);
        return label ? label.length : 0;
      },
      0,
      styleVariables.competitivePositioningMaxCharsXAxis
    );

    const maxCharsYAxis = getMaxCharsValue(
      Object.values(yTicksToConcepts),
      (item) => entityLabelFormatter('concept', item).length,
      0,
      styleVariables.competitivePositioningMaxCharsYAxis
    );
    return {
      data,
      xTicksToItems,
      yTicksToConcepts,
      maxCharsXAxis,
      maxCharsYAxis,
    };
  }

  customToolTipContent = (event) => {
    const { entityLabelFormatter } = this.props;
    const { data } = this.state;
    if (
      !(
        event &&
        event.active &&
        event.payload != null &&
        event.payload[0] != null
      )
    ) {
      return null;
    }
    const { y } = event.payload[0].payload;
    const rows = [];
    data.forEach((item) => {
      if (item.y === y) rows.push(item);
    });

    return (
      <TooltipContainer>
        <table
          style={{
            tableLayout: 'fixed',
            width: '100%',
            color: styleVariables.chartFontColor,
            fontWeight: styleVariables.fontWeightLight,
          }}
        >
          <colgroup>
            <col span="1" style={{ width: '50%' }} />
            <col span="1" style={{ width: '25%' }} />
            <col span="1" style={{ width: '25%' }} />
          </colgroup>

          <thead>
            <tr>
              <th aria-label="header-empty" />
              <th style={{ textAlign: 'center', verticalAlign: 'bottom' }}>
                <Trans id="volume" render={capitalizedTranslation}  />
              </th>
              <th style={{ textAlign: 'center', verticalAlign: 'bottom' }}>
                <Trans id="sentiment" render={capitalizedTranslation}  />
              </th>
            </tr>
          </thead>
          <tbody>
            {rows.map((item) => (
              <tr
                key={`competitive-posititioning-tooltip-${item.product_hierarchy_group}`}
                style={{
                  textAlign: 'center',
                }}
              >
                <td
                  valign="middle"
                  style={{
                    display: 'block',
                    height: '18px',
                    width: '100%',
                    overflow: 'hidden',
                  }}
                >
                  {entityLabelFormatter(
                    'productHierarchyGroup',
                    item.product_hierarchy_group
                  )}
                </td>
                <td valign="middle" style={{ textAlign: 'center' }}>
                  {item.n_chunks}
                </td>
                <td valign="middle" style={{ textAlign: 'center' }}>
                  <SentimentCell sentiment={item.average_sentiment} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </TooltipContainer>
    );
  };

  render() {
    const { loading, height, width, conceptData, entityLabelFormatter } =
      this.props;
    const {
      data,
      xTicksToItems,
      yTicksToConcepts,
      maxCharsXAxis,
      maxCharsYAxis,
      yPositionMouseOver,
    } = this.state;
    const domain = CompetitivePositioningChart.parseDomain(data);
    this.customScale = this.customScale.range(domain);
    const maxDotRadius = Math.sqrt(this.customScale(domain[1]) / Math.PI);
    if (loading) return <LoadingDataVisualization height={height} />;
    if (conceptData && !conceptData.length) {
      return (
        <EmptyDataVisualization message="Données ou écarts insuffisants" />
      );
    }

    // Set a larger x domain if too few groups, to keep groups columns close enough in layout
    const xDomain =
      xTicksToItems && Object.keys(xTicksToItems).length > 3
        ? [0, Object.keys(xTicksToItems).length - 1]
        : [-1, Object.keys(xTicksToItems).length];

    return data && data.length ? (
      <ScatterChart
        height={height}
        width={width}
        margin={{
          top:
            maxCharsXAxis *
              styleVariables.svgCharLengthFontSizeSmall *
              1.3 * // add a factor to account for increased char length due to angle (we should be considering character diagonal rather)
              Math.sin(styleVariables.competitivePositioningXAxisAngle) +
            2 * styleVariables.textSpanHeight,
          right:
            Math.max(
              maxCharsXAxis *
                styleVariables.svgCharLengthFontSizeSmall *
                Math.cos(styleVariables.competitivePositioningXAxisAngle) *
                0.8,
              styleVariables.competitivePositioningLegendWidth
            ) +
            0.5 * maxDotRadius,
          bottom: maxDotRadius + styleVariables.baseChartMargin,
          left: (maxCharsYAxis * styleVariables.svgCharWidthFontSizeSmall) / 2,
        }}
        style={{ zIndex: 1 }}
      >
        <XAxis
          allowDecimals={false}
          dataKey="x"
          type="number"
          ticks={Object.keys(xTicksToItems)}
          domain={xDomain}
          axisLine={{ stroke: styleVariables.chartFontColor }}
          tickLine={{ stroke: styleVariables.chartFontColor }}
          padding={{ left: 30, right: 10 }}
          tick={
            <CustomAxisTick
              labelMaxRows={1}
              width={maxCharsXAxis * styleVariables.svgCharLengthFontSizeSmall}
              angle={styleVariables.competitivePositioningXAxisAngle}
              axis="x"
              labelFormatter={(itemRow) =>
                entityLabelFormatter(
                  'productHierarchyGroup',
                  xTicksToItems[itemRow]
                )
              }
            />
          }
          interval={0}
          orientation="top"
          tickMargin={5}
        />
        <YAxis
          allowDecimals={false}
          dataKey="y"
          type="number"
          ticks={Object.keys(yTicksToConcepts)}
          domain={[0, Object.keys(yTicksToConcepts).length - 1]}
          padding={{ top: 30, bottom: 10 }}
          axisLine={{ stroke: styleVariables.chartFontColor }}
          tickLine={{ stroke: styleVariables.chartFontColor }}
          tick={
            <CustomAxisTick
              labelMaxRows={2}
              width={maxCharsYAxis * styleVariables.svgCharLengthFontSizeSmall}
              axis="y"
              labelFormatter={(itemRow) =>
                entityLabelFormatter('concept', yTicksToConcepts[itemRow]?.[0])
              }
            />
          }
        />
        <ZAxis
          dataKey="n_chunks"
          type="number"
          range={domain}
          scale={this.customScale}
        />
        <ReferenceLine
          y={yPositionMouseOver}
          strokeWidth={3}
          strokeDasharray="5 5"
        />
        <Scatter
          isAnimationActive={false}
          data={data}
          onMouseLeave={() => {
            setTimeout(() => this.setState({ yPositionMouseOver: -1 }), 100);
          }}
          onMouseEnter={(e) => {
            setTimeout(
              () => this.setState({ yPositionMouseOver: e.node.y }),
              100
            );
          }}
        >
          {data.map(
            ({ average_sentiment, product_hierarchy_group, db_concept }) => (
              <Cell
                key={`competitive-positioning-cell-${product_hierarchy_group}-${db_concept}`}
                fill={getInterpolatedColor(
                  average_sentiment,
                  linearInterpolation,
                  styleVariables.absoluteMinColor,
                  styleVariables.absoluteMaxColor,
                  styleVariables.absoluteMidColor
                )}
              />
            )
          )}
        </Scatter>
        {CompetitivePositioningChartLegend({
          domain,
          chartHeight: height,
          chartWidth: width,
          scale: this.customScale,
        })}
        <Tooltip
          content={this.customToolTipContent}
          cursor={false}
          style={{ zIndex: 2 }}
        />
      </ScatterChart>
    ) : null;
  }
}

CompetitivePositioningChart.propTypes = {
  entityLabelFormatter: PropTypes.func,
  conceptData: PropTypes.arrayOf(commonPropTypes.topBottomBenchmarkItem),
  competitionConceptKpis: commonPropTypes.competitionConceptKpis,
  conceptKpis: commonPropTypes.conceptKpis,
  height: PropTypes.number,
  width: PropTypes.number,
  loading: PropTypes.bool,
};

CompetitivePositioningChart.defaultProps = {
  entityLabelFormatter: (type, value) => `${type}-${value}`,
  height: undefined,
  width: undefined,
  conceptKpis: undefined,
  conceptData: undefined,
  competitionConceptKpis: undefined,
  loading: false,
};

/**
 * Responsive wrapper for CompetititvePositioningChart.
 * Doing this, we can have one of height or width as a percentage : ResponsiveContainer will provide it as a number
 * to underlying component.
 *
 * @param {*} { height, width, ...props }
 */
function ResponsiveCompetitivePositioningChart({ height, width, ...props }) {
  return (
    <ResponsiveContainer height={height} width={width}>
      <CompetitivePositioningChart {...props} />
    </ResponsiveContainer>
  );
}
ResponsiveCompetitivePositioningChart.propTypes = {
  ...CompetitivePositioningChart.propTypes,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

ResponsiveCompetitivePositioningChart.defaultProps = {
  height: 300,
  width: '100%',
};

export default ResponsiveCompetitivePositioningChart;
