import { msg } from '@lingui/macro';

export const analysisCreateDescription = [
  msg({ id: "help-message.analysis-create-description.1" }),
  msg({ id: "help-message.analysis-create-description.2" }),
];

export const analysisUpdateDescription = [
  msg({ id: "help-message.analysis-update-description" }),
];

export const analysisBaseProductHierarchyGroupDescription = [
  msg({ id: "help-message.base-product-hierarchy-group-description.1" }),
  msg({ id: "help-message.base-product-hierarchy-group-description.2" }),
];

export const analysisSourceGroupDescription = [
  msg({ id: "help-message.source-group-description" }),
];

export const analysisComparativeProductHierarchyGroupDescription = [
  msg({ id: "help-message.comparative-product-hierarchy-group-description.1" }),
  msg({ id: "help-message.comparative-product-hierarchy-group-description.2" }),
  msg({ id: "help-message.comparative-product-hierarchy-group-description.3" }),
  msg({ id: "help-message.comparative-product-hierarchy-group-description.4" }),
  msg({ id: "help-message.comparative-product-hierarchy-group-description.5" }),
];

export const volumeHelpMessage = msg({ id: "help-message.volume-description" });
export const averageSentimentHelpMessage = msg({ id: "help-message.average-sentiment-description" });

export const differentialSentimentHelpMessage = msg({ id: "help-message.benchmark-differential-sentiment-description" });

export const competitiveCumulatedVolumeHelpMessage = msg({ id: "help-message.competitive-cumulated-volume-description" });

export const categoryHelpMessage = msg({ id: "help-message.selected-category-description" });
