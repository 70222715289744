import { Divider } from 'semantic-ui-react';
import styled from 'styled-components';

import * as styleVariables from 'assets/style/variables';

export default styled(Divider)`
  &&&&&& {
    width: 100%;
    margin: 0 -${styleVariables.spaceMedium};
    position: relative;
    right: 0;
    left: 0;
    border: 1px solid rgba(222, 222, 222, 1);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.19);
  }
`;
