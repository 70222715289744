import PropTypes from 'prop-types';

import { QRCodeCanvas } from 'qrcode.react';

import logoImageSrc from 'components/ui/svg/logo.svg';

import * as svars from 'assets/style/variables';

const QR_CODE_DISPLAY_WIDTH_PX = 400;

function QrCode({ value, small, elementId, whiteAndBorder, style }) {
  const size = small ? 180 : QR_CODE_DISPLAY_WIDTH_PX;
  const includeMargin = whiteAndBorder
    ? { bgColor: 'white', includeMargin: true }
    : { bgColor: 'transparent' };
  return (
    <div role="presentation" style={{ textAlign: 'center' }}>
      <QRCodeCanvas
        id={elementId}
        value={value}
        {...includeMargin}
        fgColor={svars.colorPrimary}
        size={3 * size}
        style={{
          ...style,
          height: `${size}px`,
          width: `${size}px`,
        }}
        imageSettings={{
          src: logoImageSrc,
          excavate: true,
          height: 0,
          width: 0,
        }}
      />
    </div>
  );
}

QrCode.propTypes = {
  value: PropTypes.string,
  small: PropTypes.bool,
  elementId: PropTypes.string,
  whiteAndBorder: PropTypes.bool,
  style: PropTypes.shape({}),
};

QrCode.defaultProps = {
  value: '',
  small: false,
  elementId: null,
  whiteAndBorder: false,
  style: {},
};

export default QrCode;
