import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { PropTypes } from 'prop-types';
import styled, { css } from 'styled-components';

import { defaultItemsPerPage, defaultPage } from 'store/monitor/channelsSlice';

import BeatingLoader from 'components/ui/BeatingLoader';
import EmptyDataPage from 'components/ui/EmptyDataPage';
import Segment from 'components/ui/Segment';
import { AnalyticsAwareCheckbox } from 'components/ui/inputs/Checkbox';
import { ResettableTextInput } from 'components/ui/inputs/TextInput';
import emptyDataUrl from 'components/ui/svg/undraw_empty_xct9.svg';

import commonPropTypes from 'utils/commonPropTypes';
import { accentInsensitiveSearch } from 'utils/helpers';
import { useDebounce } from 'utils/hooks';

import * as svars from 'assets/style/variables';

import ListPagination from './Pagination';
import HeaderFactory from './header';

const CHECKBOX_STYLES = {
  fontSize: svars.fontSizeLarge,
  padding: `0 ${svars.spaceMediumLarge}`,
  flexGrow: 1,
  minWidth: '100px',
};

const sortItems = (items, { key, ascending }, accessor, sortType) =>
  items.sort((element1, element2) => {
    const element1Value = (accessor && accessor(element1)) || element1[key];
    const element2Value = (accessor && accessor(element2)) || element2[key];

    // If value is boolean do not consider `ascending` to have true value always first
    if (element1Value === true) return -1;
    if (element1Value === false) return 0;
    let sortValue = 0;
    if (element1Value !== null && element1Value !== undefined) {
      if (sortType === 'basic') {
        sortValue = element2Value - element1Value;
      } else if (element1Value?.localeCompare) {
        sortValue = element1Value.localeCompare(element2Value);
      } else if (typeof sortType === 'function') {
        sortValue = sortType(element1Value, element2Value);
      }
    } else if (element2Value !== null && element2Value !== undefined) {
      sortValue = 1;
    }

    return sortValue * (ascending ? 1 : -1);
  });

const StyledActionBar = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const MaybeClickableSegment = styled(Segment)`
  &&& {
    ${({ clickable }) =>
      clickable
        ? css`
            ${svars.hoverClickableCss}
            ${svars.activeClickableCss}
          `
        : 'background: none'}
  }
`;

function ManagementList({
  items,
  displayFavoritesOnly,
  onToggleDisplayFavouritesOnly,
  defaultSorted,
  renderItemRow,
  withTextFilter,
  textFilterPlaceholder,
  actions,
  loading,
  emptySearchHeader,
  getTextFromItem,
  emptyListHeader,
  EmptyListContent,
  onRowClick,
  rowFields,
  nActions,
  isRowActive,
  listStyle,
  testid,
  itemsToFetch,
  hasLockedProperty,
  payloadAccessor,
  updateItems,
}) {
  const dispatch = useDispatch();
  const { campaignId } = useParams();
  const [columnSorted, setColumnSorted] = useState(defaultSorted);
  const [filteredItems, setFilteredItems] = useState(items);
  const [facetSearchValue, setFacetSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(defaultPage);
  const [totalPages, setTotalPages] = useState(0);
  const [showArchived, setShowArchived] = useState(false);

  const fetchItems = useCallback(
    (page, itemsPerPage, sortField, sortOrder, searchQuery) => {
      const effectivePage = page === 0 ? 1 : page;
      dispatch(
        itemsToFetch({
          campaignId,
          page: effectivePage,
          itemsPerPage,
          sortField,
          sortOrder,
          searchQuery,
          showArchived,
        })
      )
        .then((response) => {
          setFilteredItems(response.payload[payloadAccessor]);
          setTotalPages(
            Math.ceil(response.payload.count / defaultItemsPerPage)
          );
        })
        .catch(() => {});
    },
    [dispatch, campaignId, currentPage, showArchived]
  );

  const onResetAllFilters = useCallback(() => {
    setFacetSearchValue('');
  }, [items, columnSorted]);

  const onItemSort = useCallback(
    (fieldName) => () => {
      const newFacetSorted = {
        key: fieldName,
        ascending:
          columnSorted.key !== fieldName ||
          (columnSorted.key === fieldName && !columnSorted.ascending),
      };
      setColumnSorted(newFacetSorted);
      if (!itemsToFetch) {
        setFilteredItems((prevItems) =>
          sortItems(
            [...prevItems],
            newFacetSorted,
            rowFields.find(({ id }) => id === fieldName)?.accessor
          )
        );
      }
    },
    [items, columnSorted, filteredItems]
  );

  const onUpdateSearchResults = useCallback(
    (e, { searchValue }) => {
      if (itemsToFetch) {
        setCurrentPage(1);
        fetchItems(
          currentPage,
          defaultItemsPerPage,
          columnSorted.key,
          columnSorted.ascending ? 1 : -1,
          searchValue
        );
      } else {
        const sortField = rowFields.find(({ id }) => id === columnSorted?.key);
        if (items && items.length) {
          const baseItems = displayFavoritesOnly
            ? items.filter(({ favorite }) => favorite)
            : [...items];
          const updatedFilteredItems = sortItems(
            searchValue
              ? accentInsensitiveSearch(baseItems, searchValue, getTextFromItem)
              : baseItems,
            columnSorted,
            sortField?.accessor,
            sortField?.sortType
          );
          setFilteredItems(updatedFilteredItems);
        } else {
          setFilteredItems([]);
        }
      }
    },
    [items, displayFavoritesOnly, columnSorted, showArchived]
  );

  const handlePageChange = useCallback(
    (e, { activePage }) => {
      const effectivePage = activePage === 0 ? 1 : activePage;
      setCurrentPage(effectivePage);
      if (itemsToFetch) {
        fetchItems(
          activePage,
          defaultItemsPerPage,
          columnSorted.key,
          columnSorted.ascending ? 1 : -1,
          facetSearchValue
        );
      }
    },
    [
      fetchItems,
      itemsToFetch,
      columnSorted.key,
      columnSorted.ascending,
      facetSearchValue,
    ]
  );

  const debouncedFacetSearchValue = useDebounce(
    facetSearchValue,
    itemsToFetch ? 500 : null
  );

  const onResetSearch = useCallback(() => {
    setFacetSearchValue('');
  }, []);

  const onSearchChange = useCallback((e, { value }) => {
    setFacetSearchValue(value);
  }, []);

  const HeaderComponent = ManagementList.HeaderFactory([
    ...rowFields,
    { key: 'actions', width: 55 * nActions, auto: 'true' },
    { key: 'menu', width: 10 },
  ]);

  useEffect(() => {
    onUpdateSearchResults(null, { searchValue: debouncedFacetSearchValue });
  }, [debouncedFacetSearchValue, columnSorted, onUpdateSearchResults]);

  useEffect(() => {
    if (updateItems) {
      setFilteredItems(updateItems);
    }
  }, [items]);

  return (
    <>
      <ActionBar
        textFilterPlaceholder={textFilterPlaceholder}
        onSearchChange={onSearchChange}
        onResetSearch={onResetSearch}
        facetSearchValue={facetSearchValue}
        onToggleDisplayFavouritesOnly={onToggleDisplayFavouritesOnly}
        displayFavoritesOnly={displayFavoritesOnly}
        actions={actions}
        testid={testid}
        withTextFilter={withTextFilter}
        hasLockedProperty={hasLockedProperty}
        showArchived={showArchived}
        setShowArchived={setShowArchived}
      />
      {(loading && <BeatingLoader />) ||
        (!filteredItems?.length && (
          <EmptyDataPage
            headerText={emptySearchHeader || emptyListHeader}
            illustrationUrl={emptyDataUrl}
            actionComponent={
              <EmptyListContent resetFilters={onResetAllFilters} />
            }
          />
        )) || (
          <div
            style={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto hidden',
              alignItems: 'baseline',
            }}
          >
            <HeaderComponent onSort={onItemSort} sorted={columnSorted} />
            <ItemList
              items={filteredItems}
              onRowClick={onRowClick}
              renderItemRow={renderItemRow}
              rowFields={rowFields}
              isRowActive={isRowActive}
              listStyle={listStyle}
            />
            {itemsToFetch && totalPages && filteredItems?.length ? (
              <>
                <br />
                <ListPagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  handlePageChange={handlePageChange}
                  loading={loading}
                />
              </>
            ) : null}
          </div>
        )}
    </>
  );
}

ManagementList.propTypes = {
  /** Array of items to be displayed in the list. */
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.array,
  /** Flag to show only favorite items in the list view. */
  displayFavoritesOnly: PropTypes.bool,
  /** Callback function to toggle the display of favorite items only. */
  onToggleDisplayFavouritesOnly: PropTypes.func,
  /** Default sorting configuration for the list items. */
  defaultSorted: PropTypes.shape({
    /** Field by which to sort items initially. */
    key: PropTypes.string,
    /** Specifies ascending sort order if true. */
    ascending: PropTypes.bool,
  }).isRequired,
  /** Function to render each row item in the list, taking item data as input. */
  renderItemRow: PropTypes.func.isRequired,
  /** List of fields to display in each row, defining the structure of displayed data. */
  rowFields: PropTypes.arrayOf(commonPropTypes.uiFields),
  /** Placeholder text for the text filter input field. */
  textFilterPlaceholder: PropTypes.string,
  /** Component rendering any actions associated with list items, such as create or delete. */
  actions: PropTypes.node.isRequired,
  /** Indicates if the list is loading data, often tied to fetch or async operations. */
  loading: PropTypes.bool,
  /** Header displayed when no items are found after performing a search. */
  emptySearchHeader: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  /** Function that extracts and returns the text content of each item for filtering purposes. */
  getTextFromItem: PropTypes.func,
  /** Header shown when the list is empty. */
  emptyListHeader: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  /** Custom content displayed when the list has no items. */
  EmptyListContent: PropTypes.oneOfType([PropTypes.func]),
  /** Callback function that is triggered when a row is clicked, usually for selecting or focusing on an item. */
  onRowClick: PropTypes.func,
  /** Number of actions available for each row, derived from an array length of actions. */
  nActions: PropTypes.number,
  /** Determines if a text filter should be shown for list searching. */
  withTextFilter: PropTypes.bool,
  /** Function that returns a boolean indicating if a row should be marked as active. */
  isRowActive: PropTypes.func,
  /** Unique identifier used for testing purposes, allowing easy element selection. */
  testid: PropTypes.string,
  /** Style type for list presentation, often used to apply specific class or layout options. */
  listStyle: PropTypes.shape({}),
  /** Function to retrieve the list items, required for paginated lists. */
  itemsToFetch: PropTypes.func,
  /** Boolean flag indicating if items have a locked property affecting display or actions. */
  hasLockedProperty: PropTypes.bool,
  /** Function to refresh or update the list of items; useful for real-time data updates. */
  updateItems: PropTypes.func,
  /** Specifies the property key to access items in the response data when fetched. */
  payloadAccessor: PropTypes.string,
};

ManagementList.defaultProps = {
  displayFavoritesOnly: false,
  emptySearchHeader: null,
  getTextFromItem: ({ name }) => name,
  emptyListHeader: null,
  EmptyListContent: () => null,
  items: undefined,
  onToggleDisplayFavouritesOnly: null,
  loading: false,
  onRowClick: null,
  nActions: 0,
  testid: undefined,
  withTextFilter: true,
  isRowActive: null,
  textFilterPlaceholder: null,
  listStyle: {},
  itemsToFetch: null,
  hasLockedProperty: false,
  updateItems: null,
  payloadAccessor: null,
  rowFields: [],
};
ManagementList.HeaderFactory = HeaderFactory;

export default ManagementList;

const FilterActions = styled.span`
  flex-grow: 1;
  display: flex;
  align-items: center;
  margin-right: ${svars.spaceMedium};
  flex-wrap: wrap;
`;

function ActionBar({
  textFilterPlaceholder,
  onSearchChange,
  onResetSearch,
  facetSearchValue,
  onToggleDisplayFavouritesOnly,
  displayFavoritesOnly,
  actions,
  testid,
  items,
  withTextFilter,
  hasLockedProperty,
  showArchived,
  setShowArchived,
}) {
  const { i18n } = useLingui();
  return (
    <StyledActionBar>
      <FilterActions>
        <span
          style={{
            flexGrow: 1,
            minWidth: '50px',
            margin: `${svars.spaceNormal} 0`,
          }}
        >
          {withTextFilter ? (
            <ResettableTextInput
              placeholder={textFilterPlaceholder}
              onChange={onSearchChange}
              onReset={onResetSearch}
              value={facetSearchValue}
              disabled={items?.length === 0}
              baseIconName="search"
              large={1}
              fluid
              style={{ minWidth: svars.smallInputMinWidth }}
              data-testid={testid}
            />
          ) : null}
        </span>
        {onToggleDisplayFavouritesOnly ? (
          <AnalyticsAwareCheckbox
            gaCategory="View Facet List"
            gaAction="Display favorites"
            style={CHECKBOX_STYLES}
            onClick={onToggleDisplayFavouritesOnly}
            label={i18n._(t({ id: 'display-favorites-only' }))}
            checked={displayFavoritesOnly}
          />
        ) : null}
        {hasLockedProperty ? (
          <AnalyticsAwareCheckbox
            checked={showArchived}
            onChange={() => setShowArchived(!showArchived)}
            label={i18n._(t({ id: 'unarchived-only' }))}
            data-testid="unlocked-only-toggle"
            style={CHECKBOX_STYLES}
          />
        ) : null}
      </FilterActions>
      {actions}
    </StyledActionBar>
  );
}

ActionBar.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  displayFavoritesOnly: PropTypes.bool,
  onToggleDisplayFavouritesOnly: PropTypes.func,
  textFilterPlaceholder: PropTypes.string,
  actions: PropTypes.node.isRequired,
  onSearchChange: PropTypes.func,
  onResetSearch: PropTypes.func,
  facetSearchValue: PropTypes.string,
  testid: PropTypes.string,
  withTextFilter: PropTypes.bool,
  hasLockedProperty: PropTypes.bool,
  showArchived: PropTypes.bool,
  setShowArchived: PropTypes.func,
};
ActionBar.defaultProps = {
  displayFavoritesOnly: false,
  items: undefined,
  onToggleDisplayFavouritesOnly: null,
  testid: undefined,
  onSearchChange: null,
  onResetSearch: null,
  facetSearchValue: '',
  withTextFilter: true,
  hasLockedProperty: false,
  showArchived: false,
  setShowArchived: null,
  textFilterPlaceholder: null,
};

function ItemList({
  items,
  onRowClick,
  renderItemRow,
  rowFields,
  listStyle,
  isRowActive,
}) {
  return (
    <div
      style={{
        height: '100%',
        minWidth: '100%',
        overflow: 'clip auto',
        ...listStyle,
      }}
    >
      {items.map((item) => (
        <MaybeClickableSegment
          clickable={onRowClick ? '1' : ''}
          active={isRowActive?.(item) ? '1' : ''}
          key={`facet-${item.id}`}
          onClick={onRowClick ? () => onRowClick(item) : null}
          data-testid={`bo-list-card-${item.name}`}
        >
          {renderItemRow({ fields: rowFields, item })}
        </MaybeClickableSegment>
      ))}
    </div>
  );
}

ItemList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  renderItemRow: PropTypes.func.isRequired,
  rowFields: PropTypes.arrayOf(commonPropTypes.uiFields).isRequired,
  onRowClick: PropTypes.func,
  listStyle: PropTypes.shape({}),
  isRowActive: PropTypes.func,
};
ItemList.defaultProps = {
  items: undefined,
  onRowClick: null,
  listStyle: {},
  isRowActive: null,
};
