import styled from 'styled-components';

import { getInterpolatedColor } from 'utils/colors';

import * as svars from 'assets/style/variables';

export default styled.div`
  flex-shrink: 0;
  height: 12px;
  width: 12px;
  background-color: ${(props) =>
    (props.color && props.color) ||
    (typeof props.sentiment === 'number' &&
      getInterpolatedColor(props.sentiment)) ||
    props.color};
  border-radius: 50%;
  display: inline-flex;
  text-align: center;
  align-items: center;
  align-self: center;
  ${({ withShadow }) =>
    withShadow
      ? `
    box-shadow: ${svars.colorDarkGrey} 0 0 2px;
    margin: 1px;
  `
      : ''}
`;
