import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import * as svars from 'assets/style/variables';

const ErrorMessageContainer = styled.div`
  padding-top: ${svars.spaceNormal};
  color: ${svars.colorDanger};
  min-height: ${svars.fontSizeBase};
  transition: ${svars.transitionBase};
`;

export function ErrorMessage({ message, show, style }) {
  return show ? (
    <ErrorMessageContainer style={style}>
      <Icon name="exclamation circle" />
      {message}
    </ErrorMessageContainer>
  ) : (
    <ErrorMessageContainer style={style} />
  );
}

ErrorMessage.propTypes = {
  show: PropTypes.bool,
  message: PropTypes.string.isRequired,
  style: PropTypes.shape(),
};

ErrorMessage.defaultProps = {
  style: {},
  show: false,
};

export default ErrorMessage;
