import { Link as ReactRouterLink } from 'react-router-dom';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import withClickAnalytics from 'utils/withAnalytics';

import * as svars from 'assets/style/variables';

export const RouterLink = styled(ReactRouterLink)`
  &&&&&& {
    color: ${svars.accentColor};
    font-weight: ${svars.fontWeightMedium};
    cursor: pointer;

    :active,
    :hover,
    :focus {
      color: ${svars.colorPrimary};
    }
  }
`;

export const LinkText = styled.span`
  padding: 0;
  color: ${(props) =>
    (props.inverted && svars.colorWhite) ||
    (props.primary && svars.colorPrimary) ||
    (props.base && 'inherit') ||
    svars.fontColorBase};
  font-size: ${(props) => (props.base ? 'inherit' : svars.fontSizeSmaller)};
  font-weight: ${(props) =>
    props.bold ? svars.fontWeightBold : svars.fontWeightMedium};
  border: none;
  background: transparent;
  outline: none;
`;

export const BasicLink = styled(LinkText).attrs({
  as: 'a',
})`
  cursor: pointer;
  text-decoration: underline solid;
  transition: ${svars.transitionBase};

  & i {
    text-decoration: none;
  }

  &:hover {
    color: ${(props) =>
      props.inverted ? svars.colorWhite : svars.accentColor};
    text-decoration: underline solid;
  }
  &:hover {
    color: ${({ disabled, inverted }) =>
      (disabled && 'inherit') ||
      (inverted && svars.colorWhite) ||
      svars.accentColor};
  }
`;

export const RouterLinkV2 = styled(BasicLink).attrs({
  as: ReactRouterLink,
})``;
RouterLinkV2.defaultProps = { primary: 'true' };

export const DisabledLink = styled(LinkText)`
  cursor: default;
  text-decoration: underline solid;
  color: ${svars.fontColorDisabled};
`;

/**
 * A link component that supports disable status.
 *
 * @param {*} { disabled, onClick, ...otherProps }
 */
function Link({ disabled, onClick, ...otherProps }) {
  return disabled ? (
    <DisabledLink {...otherProps} />
  ) : (
    <BasicLink onClick={onClick} {...otherProps} />
  );
}
Link.propTypes = {
  disabled: PropTypes.bool,
  // Inherits font size and color from parent
  base: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  // Use primary color as font color
  primary: PropTypes.bool,
  onClick: PropTypes.func,
};
Link.defaultProps = {
  disabled: false,
  onClick: undefined,
  primary: true,
  base: false,
};

export const AnalyticsAwareLink = withClickAnalytics(Link, 'onClick');

export default Link;
