import { useCallback } from 'react';

import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { AnalyticsAwareHoverableIconButtonWithTooltip } from 'components/ui/icon/HoverableIcon';
import { WarningIndicatorCell } from 'components/ui/table/cells/dotCells';

import * as svars from 'assets/style/variables';

const ElementContainer = styled.span`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

function DraggableElement({
  item,
  onEdit,
  onRestore,
  onRemove,
  editHelp,
  restoreHelp,
  removeHelp,
  renderHeader,
}) {
  const removeOrRestoreDraggableItem = useCallback(() => {
    if (item.archived && onRestore) {
      onRestore(item);
    } else {
      onRemove(item);
    }
  }, [item.archived]);
  const onEditItem = useCallback(() => onEdit(item), [item]);

  return (
    <ElementContainer>
      <span style={{ marginLeft: '3px' }}>
        {renderHeader ? renderHeader(item) : item.header}
      </span>

      <div style={{display: 'inline-flex'}}>
        {item.mandatory ? (
          <span
            style={{
              margin: `0 ${svars.spaceXLarge} 0 ${svars.spaceNormalLarge}`,
            }}
          >
            <WarningIndicatorCell value={<Trans id="mandatory" />} />
          </span>
        ) : null}

        <AnalyticsAwareHoverableIconButtonWithTooltip
          position="top right"
          mouseEnterDelay={500}
          mouseLeaveDelay={50}
          disabled={item.archived}
          name="pencil alternate"
          onClick={onEditItem}
          help={<span>{editHelp}</span>}
        />
        <AnalyticsAwareHoverableIconButtonWithTooltip
          position="top right"
          mouseEnterDelay={500}
          mouseLeaveDelay={50}
          large
          danger
          name={item.archived ? 'undo' : 'trash'}
          style={{ margin: `0 ${svars.spaceMedium}` }}
          onClick={removeOrRestoreDraggableItem}
          help={<span>{item.archived ? restoreHelp : removeHelp}</span>}
        />
      </div>
    </ElementContainer>
  );
}
DraggableElement.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onRestore: PropTypes.func,
  onRemove: PropTypes.func.isRequired,
  editHelp: PropTypes.string.isRequired,
  restoreHelp: PropTypes.string.isRequired,
  removeHelp: PropTypes.string.isRequired,
  renderHeader: PropTypes.func,
  item: PropTypes.shape({
    header: PropTypes.node,
    archived: PropTypes.bool,
    id: PropTypes.string,
    mandatory: PropTypes.bool,
  }).isRequired,
};
DraggableElement.defaultProps = { renderHeader: null, onRestore: null };

export const DraggableElementFactory = (
  onEdit,
  onRestore,
  onRemove,
  editHelp,
  restoreHelp,
  removeHelp,
  renderHeader
) =>
  function OverloadedDraggableElement(item) {
    return (
      <DraggableElement
        item={item}
        onEdit={onEdit}
        onRestore={onRestore}
        onRemove={onRemove}
        editHelp={editHelp}
        restoreHelp={restoreHelp}
        removeHelp={removeHelp}
        renderHeader={renderHeader}
      />
    );
  };
export default DraggableElement;
