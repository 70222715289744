import { msg } from '@lingui/macro';

const PERIOD_TYPES = {
  null: {
    type: null,
    header: msg({ id: "all-historical-data" }),
    help: msg({ id: "facet-create.all-historical-data-help" }),
    periods: [{ value: null, label: msg({ id: "all-historical-data" }) }],
  },
  complete: {
    type: 'complete',
    header: msg({ id: "last-complete-period" }),
    help: msg({ id: "facet-create.last-complete-period-help" }),
    periods: [
      {
        value: '1M',
        label: msg({ id: "1-month" }),
        abbreviation: 'C1M',
      },
      {
        value: '3M',
        label: msg({ id: "3-months" }),
        abbreviation: 'C3M',
      },
      {
        value: '6M',
        label: msg({ id: "6-months" }),
        abbreviation: 'C6M',
      },
      {
        value: '12M',
        label: msg({ id: "12-months" }),
        abbreviation: 'C12M',
      },
    ],
  },
  rolling: {
    type: 'rolling',
    header: msg({ id: "rolling-period" }),
    help: msg({ id: "facet-create.rolling-period-help" }),
    periods: [
      {
        value: '1M',
        label: msg({ id: "1-month" }),
        abbreviation: 'R1M',
      },
      {
        value: '3M',
        label: msg({ id: "3-months" }),
        abbreviation: 'R3M',
      },
      {
        value: '6M',
        label: msg({ id: "6-months" }),
        abbreviation: 'R6M',
      },
      {
        value: '1Y',
        label: msg({ id: "12-months" }),
        abbreviation: 'R12M',
      },
    ],
  },
  current: {
    type: 'current',
    header: msg({ id: "to-date-period" }),
    help: msg({ id: "facet-create.to-date-period-help" }),
    periods: [
      {
        value: '3M',
        label: msg({ id: "current-quarter" }),
        abbreviation: 'QTD',
      },
      {
        value: '6M',
        label: msg({ id: "current-semester" }),
        abbreviation: 'HTD',
      },
      {
        value: '1Y',
        label: msg({ id: "current-year" }),
        abbreviation: 'YTD',
      },
    ],
  },

  custom: {
    type: 'custom',
    header: msg({ id: "custom-period" }),
    help: msg({ id: "facet-create.custom-period-help" }),
    periods: [
      {
        value: null,
        label: msg({ id: "custom-period" }),
      },
    ],
  },
};

export const getAbbreviation = (type, periodValue) => {
  const item = PERIOD_TYPES[type].periods.find(
    ({ value }) => periodValue === value
  );
  return (item && item.abbreviation) || null;
};

export default PERIOD_TYPES;
