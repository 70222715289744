import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { i18n } from '@lingui/core';
import PropTypes from 'prop-types';

import { productHierarchyGroupsSelector } from 'selectors/entities';

import {
  CartesianGrid,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import SentimentCell from 'components/ui/cells/SentimentCell';
import EmptyDataVisualization from 'components/ui/visualization/EmptyDataVisualization';
import interactiveLegend from 'components/ui/visualization/InteractiveLegend';
import LoadingDataVisualization from 'components/ui/visualization/LoadingDataVisualization';
import RechartsTooltip from 'components/ui/visualization/RechartsTooltip';
import { ComposedChart } from 'components/ui/visualization/StyledChart';

import config from 'config';
import commonPropTypes from 'utils/commonPropTypes';
import {
  dateFormatter,
  floatFormatter,
  numberFormatter,
  zeroPrecisionPercentFormatter,
} from 'utils/formatter';
import { capitalize, objectMap } from 'utils/helpers';

import * as styleVariables from 'assets/style/variables';

const DISPLAY_OPTIONS_META = {
  average_sentiment: {
    formatter: floatFormatter,
    tooltipContent: (value) => <SentimentCell sentiment={value} />,
    domain: config.SENTIMENT_DOMAIN,
  },
  cumulated: {
    domain: [0, 'auto'],
    formatter: numberFormatter,
  },
  share_of_extracts: {
    domain: [0, 1],
    formatter: zeroPrecisionPercentFormatter,
  },
};

const getDateTicks = ([...timeSeries]) =>
  [].concat
    .apply([], [...timeSeries])
    .reduce(
      (acc, item) =>
        item.date && !acc.includes(item.date) ? [...acc, item.date] : acc,
      []
    )
    .sort();

function AlternativeTimeSeriesVisualizations({
  baseTimeSeries,
  competitionTimeSeriesMonthly,
  displayOptions,
  legendLayout,
  legendAlign,
  legendVerticalAlign,
  height,
  legendWrapperStyles,
  baseLabel,
  baseColor,
  displayOptionValue,
  colorFormatter,
  loading,
}) {
  const productHierarchyGroups = useSelector(productHierarchyGroupsSelector);
  const [legendState, setLegendState] = useState(
    interactiveLegend.getInitialState()
  );
  const onLegendStateChange = useCallback(
    (newState) => setLegendState(newState),
    [setLegendState]
  );
  if (loading) {
    return (
      <LoadingDataVisualization
        height={styleVariables.chartHeight}
        width="100%"
      />
    );
  }
  if (!baseTimeSeries || !competitionTimeSeriesMonthly) {
    return <EmptyDataVisualization height={styleVariables.chartHeight} />;
  }
  const displayOptionMeta = DISPLAY_OPTIONS_META[displayOptionValue] || {};
  const displayOption = displayOptions.find(
    ({ value }) => value === displayOptionValue
  );
  const baseDates = (baseTimeSeries || []).map((item) => item.date);
  const filteredCompetitionTimeSeries = objectMap(
    competitionTimeSeriesMonthly,
    (ts) => ts.filter((item) => baseDates.includes(item.date))
  );
  return (
    <ResponsiveContainer height={height} width="100%">
      <ComposedChart
        height={height}
        margin={styleVariables.getChartMargins(true)}
        data={baseTimeSeries}
      >
        <CartesianGrid stroke={styleVariables.cartesianGridStrokeColor} />
        <Line
          data={baseTimeSeries}
          dataKey={displayOptionValue}
          key="topbottom-ts-base-product"
          name={baseLabel}
          strokeWidth={3.5}
          stroke={baseColor}
          strokeOpacity={interactiveLegend.getOpacity(legendState, 0, 0.03, 1)}
          type="monotone"
        />
        {Object.entries(filteredCompetitionTimeSeries || {}).map(
          ([productHierarchyGroupId, series], ind) => {
            const productHierarchyGroup =
              productHierarchyGroups[productHierarchyGroupId];
            const color = colorFormatter(productHierarchyGroup.id);
            return (
              <Line
                data={series}
                dataKey={displayOptionValue}
                key={`topbottom-ts-${productHierarchyGroup.id}`}
                name={productHierarchyGroup.name}
                strokeWidth={2}
                stroke={color}
                strokeOpacity={interactiveLegend.getOpacity(
                  legendState,
                  // Add one for base time series
                  1 + ind,
                  0.03,
                  0.8
                )}
                type="monotone"
              />
            );
          }
        )}

        {interactiveLegend({
          legendState,
          onStateChange: onLegendStateChange,
          useChartIndex: true,
          wrapperStyle: legendWrapperStyles,
          layout: legendLayout,
          align: legendAlign,
          verticalAlign: legendVerticalAlign,
        })}
        <Tooltip
          wrapperStyle={{ zIndex: 2 }}
          content={
            <RechartsTooltip
              fieldKeys={['date']}
              extraFields={[
                {
                  key: displayOptionValue,
                  label: baseLabel,
                  stroke: baseColor,
                  formatter: displayOptionMeta.formatter,
                  payloadIndex: 0,
                  content: displayOptionMeta.tooltipContent,
                },
                ...Object.keys(filteredCompetitionTimeSeries || {}).map(
                  (productHierarchyGroupId, i) => {
                    const productHierarchyGroup =
                      productHierarchyGroups[productHierarchyGroupId];
                    return {
                      payloadIndex: i + 1,
                      key: displayOptionValue,
                      label: productHierarchyGroup.name,
                      formatter: displayOptionMeta.formatter,
                      stroke: colorFormatter(productHierarchyGroup.id),
                      content: displayOptionMeta.tooltipContent,
                    };
                  }
                ),
              ]}
              legendState={legendState}
            />
          }
        />
        <XAxis
          dataKey="date"
          data={baseTimeSeries}
          type="category"
          interval="preserveStartEnd"
          minTickGap={styleVariables.xAxisMinTickGap}
          ticks={getDateTicks([
            baseTimeSeries,
            ...Object.values(filteredCompetitionTimeSeries),
          ])}
          allowDuplicatedCategory={false}
          tickFormatter={dateFormatter}
          axisLine={{ stroke: styleVariables.chartFontColor }}
          tickLine={{ stroke: styleVariables.chartFontColor }}
        />
        <YAxis
          type="number"
          dataKey={displayOptionValue}
          domain={displayOptionMeta.domain}
          label={{
            value: capitalize(i18n._(displayOption.i18nText)),
            angle: 0,
            offset: 15,
            position: 'top',
            style: { textAnchor: 'start' },
          }}
          padding={{ bottom: 15, top: 0 }}
          axisLine={{ stroke: styleVariables.chartFontColor }}
          tickLine={{ stroke: styleVariables.chartFontColor }}
          tickFormatter={displayOptionMeta.formatter}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

AlternativeTimeSeriesVisualizations.propTypes = {
  baseTimeSeries: commonPropTypes.timeSeries,
  competitionTimeSeriesMonthly: PropTypes.objectOf(commonPropTypes.timeSeries),
  height: PropTypes.number,
  legendLayout: PropTypes.string,
  legendAlign: PropTypes.string,
  legendVerticalAlign: PropTypes.string,
  legendWrapperStyles: PropTypes.shape({
    paddingLeft: PropTypes.string,
    maxWidth: PropTypes.string,
  }),
  baseLabel: PropTypes.string,
  baseColor: PropTypes.string,
  displayOptionValue: PropTypes.string.isRequired,
  colorFormatter: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  displayOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number.isRequired,
      value: PropTypes.string.isRequired,
      i18nText: PropTypes.string.isRequired,
    })
  ).isRequired,
};

AlternativeTimeSeriesVisualizations.defaultProps = {
  competitionTimeSeriesMonthly: undefined,
  baseTimeSeries: undefined,
  height: 300,
  legendLayout: 'vertical',
  legendAlign: 'right',
  legendVerticalAlign: 'middle',
  legendWrapperStyles: undefined,
  baseLabel: 'Mon produit',
  baseColor: 'red',
  loading: false,
};

export default AlternativeTimeSeriesVisualizations;
