import { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';

import { api } from 'actions/utils';

import Link from 'components/ui/Link';
import { AnalyticsAwareButton } from 'components/ui/button/index';

import commonPropTypes from 'utils/commonPropTypes';

import {
  LoginHeaderMessageContainer,
  LoginMessageContainer,
} from './LoginLayout';

function RequestMessage({ onToggleSupportModal, requestError }) {
  return (
    <LoginMessageContainer error={requestError}>
      {(requestError && (
        <Trans>
          Une erreur inattendue s&apos;est produite. Attendez quelques minutes
          et essayez de recharger cette page pour renvoyer le lien.
          <br /> Si l&apos;erreur persiste{' '}
          <Link base="true" onClick={onToggleSupportModal}>
            contacter le support.
          </Link>
        </Trans>
      )) ||
        (requestError === false && <Trans id="password-update.link-sent" />) ||
        null}
    </LoginMessageContainer>
  );
}

RequestMessage.propTypes = {
  onToggleSupportModal: PropTypes.func.isRequired,
  requestError: PropTypes.bool.isRequired,
};

async function requestNewAccountCreationLink(
  setLoading,
  setRequestError,
  searchParams,
  language
) {
  setRequestError(undefined);
  setLoading(true);
  let newRequestError = false;
  let response;
  try {
    response = await api.post('/user/resend-activation-token', {
      user_email: searchParams.get('user'),
      locale: language,
    });
    if (response?.data?.message === 'ok') {
      newRequestError = false;
    }
  } catch (error) {
    newRequestError = true;
  }
  setRequestError(newRequestError);
  setLoading(false);
}

function ExpiredLink({ language, onToggleSupportModal }) {
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [requestError, setRequestError] = useState(undefined);

  const handleRequestNewLink = useCallback(() => {
    requestNewAccountCreationLink(
      setLoading,
      setRequestError,
      searchParams,
      language
    );
  }, [searchParams, language]);

  return (
    <>
      <LoginHeaderMessageContainer>
        <Trans id="password-update.expired-link" />
      </LoginHeaderMessageContainer>
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <AnalyticsAwareButton
          loading={loading}
          onClick={() => !loading && handleRequestNewLink()}
          disabled={requestError === false}
        >
          <Trans id="password-update.resend-link" />
        </AnalyticsAwareButton>
      </div>
      <RequestMessage
        onToggleSupportModal={onToggleSupportModal}
        requestError={requestError}
      />
    </>
  );
}

export default ExpiredLink;

ExpiredLink.propTypes = {
  language: commonPropTypes.language.isRequired,
  onToggleSupportModal: PropTypes.func.isRequired,
};

ExpiredLink.defaultProps = {};
