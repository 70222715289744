import { Header as BaseHeader } from 'semantic-ui-react';
import styled from 'styled-components';

import * as svars from 'assets/style/variables';

const makeHeader = (
  color,
  fontSize,
  fontFamily,
  fontWeight,
  subHeaderFontSize,
  subHeaderColor = null
) => styled(BaseHeader)`
  &&& {
    &:first-letter {
      text-transform: uppercase;
    }

    color: ${color};
    font-size: ${fontSize};
    font-family: ${fontFamily};
    font-weight: ${fontWeight};
    ${({ nomargin }) => (nomargin ? 'margin: 0;' : '')};
    display: ${({ inline }) => (inline ? 'inline' : 'block')};

    & .sub.header {
      max-width: ${svars.textMaxWidth};
      font-size: ${subHeaderFontSize};
      font-weight: ${svars.fontWeightLight};
      color: ${subHeaderColor || color};
    }
  }
`;

const SmallHeader = makeHeader(
  svars.fontColorLighter,
  svars.fontSizeSmaller,
  svars.fontFamilyTitle,
  svars.fontWeightMedium,
  svars.fontSizeXSmall
);

const Header = makeHeader(
  'inherit',
  svars.fontSizeMedium,
  svars.fontFamilyTitle,
  svars.fontWeightMedium,
  svars.fontSizeBase
);

const LightHeader = makeHeader(
  svars.fontColorBase,
  svars.fontSizeMedium,
  svars.fontFamilyTitle,
  svars.fontWeightBase,
  svars.fontSizeBase
);

export const LightLargeHeader = makeHeader(
  svars.fontColorBase,
  svars.fontSizeLarge,
  svars.fontFamilyTitle,
  svars.fontWeightLight,
  svars.fontSizeBase
);

const LargeHeader = makeHeader(
  svars.fontColorBase,
  svars.fontSizeBig,
  svars.fontFamilyTitle,
  svars.fontWeightSemiBold,
  svars.fontSizeXLarge
);

SmallHeader.Content = BaseHeader.Content;
Header.Content = BaseHeader.Content;
LargeHeader.Content = BaseHeader.Content;

export default Header;
export { SmallHeader, LargeHeader, LightHeader };
