import PropTypes from 'prop-types';
import { Pagination } from 'semantic-ui-react';
import styled from 'styled-components';

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const StyledPagination = styled(Pagination)`
  &&& {
    .active.item {
      align-items: center;
      justify-content: center;
    }
    .item {
      justify-content: center;
    }
  }
`;

function ListPagination({
  currentPage,
  totalPages,
  handlePageChange,
  loading,
}) {
  const prevItem = {
    content: <i className="left arrow icon" />,
    icon: true,
    disabled: currentPage === 1,
  };

  const nextItem = {
    content: <i className="right arrow icon" />,
    icon: true,
    disabled: currentPage === totalPages,
  };

  return (
    <PaginationWrapper>
      <StyledPagination
        disabled={!!loading}
        activePage={currentPage}
        ellipsisItem={{ content: '...', icon: true }}
        totalPages={totalPages}
        onPageChange={handlePageChange}
        size="small"
        secondary
        firstItem={null}
        lastItem={null}
        prevItem={prevItem}
        nextItem={nextItem}
      />
    </PaginationWrapper>
  );
}

ListPagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ListPagination;
