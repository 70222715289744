import React, { Component } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { renderSvgTextSpans } from 'utils/svg';

import * as svars from 'assets/style/variables';

const textSpanHeight = 11;

const LabelText = styled.text`
  pointer-events: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  ms-user-select: none;
`;

export const getLabelPosition = ({ left, right, bottom, top }) => {
  const labelWidth = Math.abs(left - right);
  const labelHeight = Math.abs(bottom - top);
  const centerX = left + labelWidth / 2;
  const centerY = bottom + labelHeight / 2;
  return { position: { x: centerX, y: centerY }, labelWidth, labelHeight };
};

class ScatterChartLabel extends Component {
  constructor(props) {
    super(props);
    const labelRectangle =
      (props.rectangles && props.rectangles[props.index]) || {};
    this.state = {
      active: false,
      offset: {},
      labelWidth: null,
      labelHeight: null,
      checked: labelRectangle.checked,
      displayed: labelRectangle.displayed,
      ...(labelRectangle.position || {}),
    };
  }

  // static updatePosition = (props) => {
  //   const { rectangles, formatter, value } = props;
  //   const formatted = formatter(value);
  //   let cX = null;
  //   let cY = null;
  //   const rectangle = rectangles.find(({ id }) => id === formatted);
  //   if (!rectangle) return null;
  //   const { left, right, bottom, top } = rectangle.rectangle;
  //   const labelWidth = Math.abs(left - right);
  //   const labelHeight = Math.abs(bottom - top);
  //   cX = left;
  //   cY = bottom;
  //   const centerX = cX + labelWidth / 2;
  //   const centerY = cY + labelHeight / 2;
  //   return { position: { x: centerX, y: centerY }, labelWidth, labelHeight };
  // };

  handlePointerDown = (e) => {
    const el = e.target;
    const bbox = e.target.getBoundingClientRect();
    const x = e.clientX - bbox.left;
    const y = e.clientY - bbox.top;
    el.setPointerCapture(e.pointerId);
    this.setState({
      active: true,
      offset: {
        x,
        y,
      },
    });
  };

  handlePointerMove = (e) => {
    const { top, bottom, right, left } = this.props;
    const { position, active, offset } = this.state;
    const bbox = e.target.getBoundingClientRect();
    const x = e.clientX - bbox.left;
    const y = e.clientY - bbox.top;
    const finalX = position.x - (offset.x - x);
    const finalY = position.y - (offset.y - y);
    const halfWidth = (bbox.right - bbox.left) / 2;
    const height = bbox.bottom - bbox.top;
    if (active) {
      this.setState({
        position: {
          x: Math.max(left + halfWidth, Math.min(right - halfWidth, finalX)),
          y: Math.max(top + height, Math.min(bottom + height, finalY)),
        },
      });
    }
  };

  handlePointerUp = () => {
    const { setPosition, index } = this.props;
    const { position } = this.state;
    this.setState({
      active: false,
    });
    setPosition(index, position.x, position.y);
  };

  render() {
    const { cx, cy, formatter, value } = this.props;
    const { position, labelHeight, labelWidth, checked, displayed, active } =
      this.state;
    if (!position || !checked || displayed === false) return null;
    return (
      <g>
        <line
          x1={cx}
          y1={cy}
          x2={position.x}
          y2={position.y - labelHeight}
          stroke={svars.fontColorBase}
          strokeOpacity="0.5"
        />
        <ellipse
          style={{ cursor: active ? 'grabbing' : 'pointer' }}
          onPointerDown={this.handlePointerDown}
          onPointerUp={this.handlePointerUp}
          onPointerMove={this.handlePointerMove}
          fill="transparent"
          cx={position.x}
          cy={position.y - labelHeight}
          rx={labelWidth / 2}
          ry={labelHeight / 2}
        />
        <LabelText
          filter="url(#whiteOutlineEffect)"
          width={labelWidth}
          height={labelHeight}
          textAnchor="middle"
          alignmentBaseline="baseline"
        >
          {renderSvgTextSpans(
            formatter(value),
            position.x,
            position.x + labelWidth,
            position.y - 1.5 * labelHeight,
            position.y,
            null,
            false,
            { style: { fontSize: '0.8rem' } },
            true,
            4.5,
            textSpanHeight
          )}
        </LabelText>
      </g>
    );
  }
}

ScatterChartLabel.propTypes = {
  value: PropTypes.string.isRequired,
  formatter: PropTypes.func,
  setPosition: PropTypes.func.isRequired,
  cx: PropTypes.number.isRequired,
  cy: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  rectangles: PropTypes.arrayOf(
    PropTypes.shape({
      rectangle: PropTypes.shape({
        top: PropTypes.number,
        bottom: PropTypes.number,
        right: PropTypes.number,
        left: PropTypes.number,
      }),
    })
  ).isRequired,
  top: PropTypes.number.isRequired,
  bottom: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
  right: PropTypes.number.isRequired,
};
ScatterChartLabel.defaultProps = { formatter: (item) => item };

export default React.memo(
  ScatterChartLabel,
  (prevProps, nextProps) => prevProps.value !== nextProps.value
);
